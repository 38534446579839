import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { Modal } from "antd"
import { initializeHubspotForm } from "../../../../utils/gatsby-helpers"

const CardContactForm = ({ name, image, description, button, form, extraClassName = null,animation = {} }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const showModal = () => setIsModalVisible(true)
    const handleCancel = () => setIsModalVisible(false)

    let className = "card "

    if (extraClassName) {
        className += extraClassName
    }

    initializeHubspotForm(form.portal_id, form.form_id, `#form-contact-${form.form_id}`, form.region)

    return <div className={className} {...animation}>
        <div className="card__body">
            <img src={image.localFile.publicURL} alt={image.alternativeText} />
            <div className="card__body__description">
                <h5>{name}</h5>
                <ReactMarkdown children={description} rehypePlugins={[rehypeRaw]} />
                <Modal width={"100%"} visible={isModalVisible} onCancel={handleCancel} footer={null}>
                    <div id={`form-contact-${form.form_id}`} className="form-hb"/>
                </Modal>
            </div>
            <button className="card__body__see-more" onClick={showModal}>{button} <i className="icon-arrow-right"></i></button>
        </div>
    </div>
}

export default CardContactForm
